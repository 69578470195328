import * as bootstrap from "bootstrap";

const popoverTriggers = document.querySelectorAll('[data-bs-toggle="popover"]');
[...popoverTriggers].map((popoverTrigger) => new bootstrap.Popover(popoverTrigger));

import('./modules/sidebar').then(module => new module.Sidebar());
import('./modules/global-search').then(module => new module.GlobalSearch());
import('./modules/invalid-form-reset').then(module => module.invalidFormReset());
import('./modules/alert').then(module => module.alert());

if (location.pathname.startsWith('/categories/')) {
    import('./modules/category-filters').then(module => new module.CategoryFilters());
}

if (/^\/(categories\/|statistics\/|companies\/|offers)/.test(location.pathname)) {
    import('./modules/more-items').then(module => module.moreItems());
}

if (location.pathname.startsWith('/requests/')) {
    import('./modules/offers/offer-response-manager').then(module => new module.OfferResponseManager());
    import('./modules/offers/offer-request-search').then(module => new module.OfferRequestSearch());
    import('./modules/offers/offer-requests').then(module => new module.OfferRequests());
}

if (location.pathname.startsWith('/statistics/')) {
    import('./modules/stats-range-picker').then(module => new module.StatsRangePicker());
}

if (location.pathname.startsWith('/products/')) {
    import('./modules/image-carousel').then(module => module.imageCarousel());
}

if (/^\/(categories\/|products\/|bookmarks|companies\/)/.test(location.pathname)) {
    import('./modules/offers/offer-request-manager').then(module => new module.OfferRequestManager());
}

if (location.pathname.startsWith('/register') || location.pathname.startsWith('/profile/edit')) {
    import('./modules/registration').then(module => new module.Registration());
}

if (location.pathname.startsWith('/offers')) {
    import('./modules/offers/offer-price').then(module => new module.OfferPrice());
}

if (location.pathname.startsWith('/promo')) {
    import('./modules/promo').then(module => module.promo());
}
